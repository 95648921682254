import useViewer, { useViewerSupportedLanguages } from "hooks/useViewer";

import { URLLocalizer, usePathname } from "@holibob-packages/ui-core/navigation";

export type SelectedLanguage = {
    name: string;
    href: string;
};
export interface LanguageObj {
    label: string;
    language: string;
    href: string;
}

const LANGUAGE_OPTIONS = [
    { language: "en", label: "English" },
    { language: "fr", label: "Français" },
    { language: "it", label: "Italiano" },
    { language: "ar", label: "عربى" },
    { language: "de", label: "Deutsch" },
    { language: "es", label: "Español" },
    { language: "pt", label: "Português" },
    { language: "nl", label: "Nederlands" },
] as const;

function useLanguagesList() {
    const supportedLanguages = useViewerSupportedLanguages();
    return LANGUAGE_OPTIONS.filter((option) =>
        supportedLanguages.find((language) => language.urlCode === option.language)
    );
}

export function useLanguageSelectOptions() {
    const pathname = usePathname();
    const localizer = new URLLocalizer(useViewer());

    const languagesList = useLanguagesList();
    const options = languagesList.map<LanguageObj>((lang) => {
        return {
            ...lang,
            key: lang.language,
            href: localizer.localizePathname(lang.language, pathname),
        };
    });

    return options;
}
