import MenuIcon from "@mui/icons-material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useState, lazy, Suspense } from "react";

import { HeaderNavigationNode } from "../HeaderNavigation";

const HeaderNavigationMobileDrawer = lazy(() => import("./HeaderNavigationMobileDrawer"));

const MobileButton = styled(IconButton)(({ theme }) => ({
    display: "none",
    color: "inherit",
    [theme.breakpoints.down("md")]: {
        display: "flex",
    },
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
    padding: "8px",
}));

function useNodesListsToRender(nodes: HeaderNavigationNode[], openedNodesPath: number[]) {
    const lists: Array<{ nodePrefix: number[]; nodes: HeaderNavigationNode[] }> = [
        {
            nodePrefix: [],
            nodes,
        },
    ];

    let currentNodes = nodes;
    const currentPrefix: number[] = [];
    for (const nodeIndex of openedNodesPath) {
        const node = currentNodes[nodeIndex];
        currentPrefix.push(nodeIndex);
        if (node.children) {
            lists.push({
                nodePrefix: currentPrefix,
                nodes: node.children,
            });
            currentNodes = node.children;
        }
    }

    return lists;
}

export function HeaderNavigationMobile({ nodes }: { nodes: HeaderNavigationNode[] }) {
    const [isOpen, setOpen] = useState(false);
    const [currentNodePath, setCurrentNodePath] = useState<number[]>([]);
    const nodeListsToRender = useNodesListsToRender(nodes, currentNodePath);
    const closeDrawer = () => {
        setOpen(false);
    };
    const onClose = () => {
        closeDrawer();
        setCurrentNodePath([]);
    };

    return (
        <Suspense fallback={<StyledCircularProgress size={24} />}>
            <MobileButton
                onClick={() => {
                    setOpen(true);
                }}
            >
                <MenuIcon />
            </MobileButton>
            {isOpen && (
                <HeaderNavigationMobileDrawer
                    currentNodePath={currentNodePath}
                    nodeListsToRender={nodeListsToRender}
                    onClose={onClose}
                    setCurrentNodePath={setCurrentNodePath}
                    closeDrawer={closeDrawer}
                />
            )}
        </Suspense>
    );
}
