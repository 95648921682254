import useStoreBooking from "hooks/useStoreBooking";
import noop from "lodash/noop";
import { createContext } from "react";

import { BookingManagerReturnValue, useBookingManager } from "@holibob-packages/ui-core/apiHooks";
import { useBookingFormBaseContextCreate } from "@holibob-packages/ui-core/booking-ui";
import { noopAsync } from "@holibob-packages/ui-core/utils";

import { useContextHelper } from "./useContextHelper";

export const ShoppingCartContext = createContext<BookingManagerReturnValue>({
    booking: undefined,
    isCommitting: false,
    setInput: noop,
    loading: false,
    error: undefined,
    bookingDeleteAvailability: noopAsync,
    bookingCommit: noopAsync,
    bookingCancel: noopAsync,
    refetch: noopAsync,
});

export function useShoppingCartCreateContext() {
    const [, bookingId] = useStoreBooking();
    return useBookingManager({ bookingId: bookingId ?? "" });
}

export function useShoppingCartBookingId() {
    const [, bookingId] = useStoreBooking();

    return bookingId;
}
export function useShoppingCartContext() {
    return useContextHelper(ShoppingCartContext, "ShoppingCartContext");
}

export function useShoppingCartBooking() {
    const { booking } = useShoppingCartContext();
    return booking;
}

export function useShoppingCartBookingAvailabilities() {
    const booking = useShoppingCartBooking();
    const availabilities = booking?.availabilities ?? [];
    return availabilities;
}

export function useShoppingCartSaleCurrency() {
    const booking = useShoppingCartBooking();

    const saleCurrency = booking?.saleCurrency ?? {};
    return saleCurrency;
}

export function useShoppingCartDeleteAvailability() {
    const { bookingDeleteAvailability } = useShoppingCartContext();

    return bookingDeleteAvailability;
}

export function useBookingFormShoppingCartContextCreate() {
    const bookingManagerData = useShoppingCartContext();

    return useBookingFormBaseContextCreate(bookingManagerData);
}
