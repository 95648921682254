import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNextTranslation } from "hooks/useTranslation";
import { useState } from "react";

import { usePathname } from "@holibob-packages/ui-core/navigation";

const SelectStyled = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    width: "100%",
}));

export type HeaderCurrencySelectProps = {
    buttonClassName?: string;
    onSelect: (value: string) => void;
    selectedCurrency?: string;
    currencies?: Array<{ codeWithoutSymbol: string }>;
    disabled?: boolean;
};

export const HeaderCurrencySelect = ({
    onSelect,
    selectedCurrency,
    currencies,
    disabled,
}: HeaderCurrencySelectProps) => {
    const pathname = usePathname();
    const [t] = useNextTranslation("navbar");
    const selectCurrencyLabel = t("label.selectCurrency");
    const [selectedValue, setSelectedValue] = useState(selectedCurrency);

    if (pathname.startsWith("/booking/")) {
        return null;
    }

    const handleCurrencySelection = (value: string) => {
        setSelectedValue(value);
        onSelect(value);
    };

    return (
        <>
            <FormControl fullWidth variant="outlined">
                <InputLabel>{selectCurrencyLabel}</InputLabel>
                <SelectStyled
                    data-testid="currency-select-button"
                    value={selectedValue}
                    disabled={disabled}
                    onChange={(event) => handleCurrencySelection(event.target.value as string)}
                    label={selectCurrencyLabel}
                >
                    <MenuItem value={selectedCurrency} disabled={true}>
                        {selectedCurrency}
                    </MenuItem>
                    {currencies?.map((option) => (
                        <MenuItem key={option.codeWithoutSymbol} value={option.codeWithoutSymbol}>
                            {option.codeWithoutSymbol}
                        </MenuItem>
                    ))}
                </SelectStyled>
            </FormControl>
        </>
    );
};

export default HeaderCurrencySelect;
