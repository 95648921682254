import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import { CircularProgress, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { ButtonPrimary } from "components/Button";
import useCurrency from "hooks/useCurrency";
import useViewer from "hooks/useViewer";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- It's fine here
import { useRouter as useNextRouter } from "next/navigation";
import { useState, useTransition } from "react";

import { Spacer } from "@holibob-packages/ui-core/components";
import { useLanguage } from "@holibob-packages/ui-core/hooks";
import { URLLocalizer, usePathname } from "@holibob-packages/ui-core/navigation";
import { makeStyles } from "@holibob-packages/ui-core/style";

import Modal, { ModalContent } from "../../components/Modal";
import HeaderCurrencySelect from "./HeaderButtons/HeaderCurrencySelect";
import HeaderLanguageSelect from "./HeaderButtons/HeaderLanguageSelect";
import { useCurrencySelectOptions } from "./utils/useCurrencySelectOptions";
import { SelectedLanguage, useLanguageSelectOptions } from "./utils/useLanguageSelectionOptions";

const useStyles = makeStyles()({
    applyBtn: {
        width: "100%",
        cursor: "pointer",
    },
});

const ModalCloseButtonWrapper = styled("div")({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
});

const CloseIconButton = styled(IconButton)({
    margin: 0,
    padding: 0,
});

const StyledModalContent = styled(ModalContent)({
    width: "25rem",
});

export const HeaderLanguageAndCurrencyButton = () => {
    const { classes } = useStyles();
    const [currency, changeGlobalCurrency] = useCurrency();
    const [open, setOpen] = useState(false);
    const defaultLanguage = useLanguage();
    const localizer = new URLLocalizer(useViewer());
    const [isSelectionMade, setIsSelectionMade] = useState(false);
    const pathname = usePathname();
    const initialHref = localizer.localizePathname(defaultLanguage.urlCode, pathname);
    const [selectedLanguage, setSelectedLanguage] = useState({ name: defaultLanguage.name, href: initialHref });
    const [selectedCurrency, setSelectedCurrency] = useState(currency);
    const languageSelectOptions = useLanguageSelectOptions();
    const currencySelectOptions = useCurrencySelectOptions().currencySelectOptions;
    const router = useNextRouter();
    const [isPending, startTransition] = useTransition();
    const isBookingPage = pathname.startsWith("/booking/");
    const showGlobeIcon = languageSelectOptions.length > 0 || (currencySelectOptions.length > 0 && !isBookingPage);

    const handleLanguageSelection = ({ name, href }: SelectedLanguage) => {
        setIsSelectionMade(true);
        setSelectedLanguage({ name, href });
    };

    const handleCurrencySelection = (value: string) => {
        setIsSelectionMade(true);
        setSelectedCurrency(value);
    };

    const applySelections = () => {
        changeGlobalCurrency(selectedCurrency);

        startTransition(() => {
            if (selectedLanguage.href !== initialHref) {
                router.replace(selectedLanguage.href);
            }

            router.refresh();
            setOpen(false);
        });
    };

    const handleClose = () => {
        if (isPending) return;

        setOpen(false);
        const { name } = defaultLanguage;
        setSelectedLanguage({ name, href: initialHref });
        setSelectedCurrency(currency);
        setIsSelectionMade(false);
    };

    return (
        <>
            {showGlobeIcon ? (
                <IconButton
                    id="language-select-button"
                    data-testid="languageSelectButton-mobile"
                    color="inherit"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    <LanguageIcon />
                </IconButton>
            ) : null}
            <Modal open={open} onClose={handleClose} data-testid="language-currency-modal">
                <StyledModalContent>
                    <ModalCloseButtonWrapper>
                        <CloseIconButton disabled={isPending} onClick={handleClose} size="medium">
                            <CloseIcon />
                        </CloseIconButton>
                    </ModalCloseButtonWrapper>
                    <Spacer sm />
                    <Stack direction="row" gap={2} flexWrap="wrap">
                        {languageSelectOptions.length > 0 && (
                            <HeaderLanguageSelect
                                onSelect={handleLanguageSelection}
                                selectedLanguage={selectedLanguage}
                                languages={languageSelectOptions}
                                disabled={isPending}
                            />
                        )}
                        {currencySelectOptions.length > 1 && (
                            <HeaderCurrencySelect
                                onSelect={handleCurrencySelection}
                                selectedCurrency={selectedCurrency}
                                currencies={currencySelectOptions}
                                disabled={isPending}
                            />
                        )}
                        <ButtonPrimary
                            disabled={isPending || !isSelectionMade}
                            className={classes.applyBtn}
                            onClick={applySelections}
                            endIcon={isPending && <CircularProgress size={20} />}
                        >
                            Apply
                        </ButtonPrimary>
                    </Stack>
                </StyledModalContent>
            </Modal>
        </>
    );
};
