import Text from "components/Text";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles<void, "labelValueSeperator" | "labelValueLabelText">()((_theme, _params, classes) => ({
    labelValueLayout: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
        '&[data-variant="vertical"]': {
            flexDirection: "column",
            [`& .${classes.labelValueSeperator}`]: {
                display: "none",
            },
            [`& .${classes.labelValueLabelText}`]: {
                fontSize: "0.8em",
            },
        },
    },
    labelValue: {},
    labelValueText: {
        color: "inherit",
        transition: "all 0.2s",
        "&:-webkit-any-link": {
            color: "#00CFB3",
        },
        "&:-webkit-any-link:hover": {
            color: "#00CFB3",
        },
    },

    labelValueSeperator: {
        marginRight: 8,
        marginLeft: 1,
        color: "#949494",
    },

    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "#D6D1CA",
        marginBottom: 10,
    },
    gutterBottom: {
        marginBottom: 30,
    },
    gutterTop: {
        marginTop: 20,
    },
    labelValueLabelText: {
        color: "#949494",
        fontWeight: 400,
    },
    labelValueValueText: {},
    labelValueValueTextPlaceholder: {
        opacity: 0.3,
    },
}));

export function LabelValueLabel({ label }: $TSFixMe) {
    const { classes, cx } = useStyles();
    const className = cx(classes.labelValueLabelText, "LabelValueLabel");
    return (
        <Text data-testid={`labelValueLabel-${label}`} className={className}>
            {label}
        </Text>
    );
}

export function LabelValueValue({
    label,
    value: valueProp,
    href,
    rel,
    target,
    placeholder = "Not Specified",
}: $TSFixMe) {
    const { classes, cx } = useStyles();

    const className = cx(
        classes.labelValueValueText,
        !valueProp && classes.labelValueValueTextPlaceholder,
        "LabelValueValue"
    );

    const value = valueProp || placeholder;

    return (
        <Text data-testid={`labelValueValue-${label}`} className={className} target={target} rel={rel} href={href}>
            {value}
        </Text>
    );
}

export function LabelValueSeperator({ seperator = ":" }) {
    const { classes, cx } = useStyles();
    return <Text className={cx(classes.labelValueSeperator, "LabelValueSeperator")}>{seperator}</Text>;
}

export function LabelValueLayout({
    className: classNameProp,
    labelElement,
    seperatorElement,
    variant,
    valueElement,
    gutterBottom,
    gutterTop,
}: $TSFixMe) {
    const { classes, cx } = useStyles();

    const className = cx(
        classes.labelValueLayout,
        classNameProp,
        gutterBottom && classes.gutterBottom,
        gutterTop && classes.gutterTop
    );

    return (
        <div className={className} data-variant={variant}>
            {labelElement}
            {seperatorElement}
            {valueElement}
        </div>
    );
}

export function LabelValue({ className, variant, ...props }: $TSFixMe) {
    const { classes, cx } = useStyles();

    return (
        <LabelValueLayout
            variant={variant}
            className={cx(classes.labelValue, classes.labelValueText, className)}
            labelElement={<LabelValueLabel {...props} />}
            seperatorElement={<LabelValueSeperator {...props} />}
            valueElement={<LabelValueValue {...props} />}
            {...props}
        />
    );
}

export default LabelValue;
