import { useAppState } from "hooks/useAppState";
import { useEffect, useCallback } from "react";

export const useGlobalSearchIsOpen = (isOpenProp = false) => {
    const [state, dispatch] = useAppState();

    useEffect(() => {
        dispatch({
            type: isOpenProp ? "GLOBAL_SEARCH_OPEN" : "GLOBAL_SEARCH_CLOSE",
        });
    }, [isOpenProp, dispatch]);

    return state.globalSearchIsOpen;
};

export const useGlobalSearchOpen = () => {
    const [, dispatch] = useAppState();

    const open = useCallback(() => {
        dispatch({
            type: "GLOBAL_SEARCH_OPEN",
        });
    }, [dispatch]);

    return open;
};

export const useGlobalSearchClose = () => {
    const [, dispatch] = useAppState();

    const close = useCallback(() => {
        dispatch({
            type: "GLOBAL_SEARCH_CLOSE",
        });
    }, [dispatch]);

    return close;
};

export default useGlobalSearchIsOpen;
