import { ConsumerTripWLSearchButtonType } from "@holibob-packages/consumer-trip-event";

export class SearchTriggerEvent extends Event {
    static NAME = "destination:search-triggered";

    constructor(
        readonly details: {
            searchButtonType: ConsumerTripWLSearchButtonType;
        }
    ) {
        super(SearchTriggerEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
