"use client";

import { useSearchParams } from "next/navigation";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import HolibobCookieStorage from "utils/HolibobCookieStorage";

import { useRouter, usePathname } from "@holibob-packages/ui-core/navigation";

import { useContextHelper } from "./useContextHelper";

export type CurrencyContextValue = ReturnType<typeof useCurrencyCreateContext>;
export const CurrencyContext = createContext<CurrencyContextValue | null>(null);

function useCurrencyCreateContext(initialCurrency: string) {
    const [currency, setCurrency] = useState(initialCurrency);

    /**
     * pathname and searchParams are saved to a ref so that we don't cause a re-render by changing
     * the identity of the changeCurrency function.
     */
    const pathnameLive = usePathname();
    const pathnameRef = useRef(pathnameLive);
    useEffect(() => {
        pathnameRef.current = pathnameLive;
    }, [pathnameLive]);

    const searchParamsLive = useSearchParams();
    const searchParamsRef = useRef(searchParamsLive);
    useEffect(() => {
        searchParamsRef.current = searchParamsLive;
    }, [searchParamsLive]);

    const router = useRouter();

    const changeCurrency = useCallback(
        (currency: string) => {
            setCurrency(currency);
            HolibobCookieStorage.setItem(null, "x-holibob-currency", currency);

            const searchParams = searchParamsRef.current;
            const pathname = pathnameRef.current;

            // if there used to be a "currency" parameter, remove it
            if (searchParams.has("currency") && searchParams.get("currency") !== currency) {
                const params = new URLSearchParams(searchParams);
                params.delete("currency");
                router.replace(pathname + (params.size ? "?" + params.toString() : ""));
            }
        },
        [router]
    );

    return useMemo(() => {
        return [currency, changeCurrency] as const;
    }, [changeCurrency, currency]);
}

export function useCurrency() {
    return useContextHelper(CurrencyContext);
}

export function CurrencyProvider({
    children,
    initialCurrency,
}: {
    children: React.ReactNode;
    initialCurrency: string;
}) {
    const value = useCurrencyCreateContext(initialCurrency);

    return <CurrencyContext.Provider value={value}>{children}</CurrencyContext.Provider>;
}

export default useCurrency;
