import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { useGlobalSearchOpen } from "hooks/useGlobalSearchIsOpen";
import { useRef } from "react";

import { SearchTriggerEvent } from "@holibob-packages/ui-core/custom-events";
import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    button: {},
});

const GlobalSearchButtonMobile = ({ className }: { className?: string }) => {
    const open = useGlobalSearchOpen();
    const { classes, cx } = useStyles();
    const ref = useRef<HTMLButtonElement>(null);

    const handleClick = () => {
        open();
        ref.current?.dispatchEvent(new SearchTriggerEvent({ searchButtonType: "GLOBAL_SEARCH_NAVBAR_ICON" }));
    };

    return (
        <IconButton
            ref={ref}
            onClick={handleClick}
            color="inherit"
            className={cx(className, classes.button)}
            size="small"
        >
            <SearchIcon />
        </IconButton>
    );
};

const GlobalSearchButton = ({ className }: { className?: string }) => {
    return <GlobalSearchButtonMobile className={className} />;
};

export default GlobalSearchButton;
