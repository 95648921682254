import GlobalSearchButton from "components/GlobalSearchButton";
// import { useIsHomePage } from "hooks/useIsHomePage";
import { useViewerIsSearchBarSuppressed } from "hooks/useViewer";

export const HeaderGlobalSearchButton = ({ buttonClassName }: { buttonClassName?: string }) => {
    // const isHomePage = useIsHomePage();
    const isSearchBarSuppressed = useViewerIsSearchBarSuppressed();

    if (isSearchBarSuppressed) return null;
    // if (isHomePage) return null;

    return <GlobalSearchButton className={buttonClassName} />;
};
