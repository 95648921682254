import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const LinerLoadingWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    "& > * + *": {
        marginTop: theme.spacing(2),
    },
    height: 3,
}));

type LinearLoadingProps = {
    dataTestId?: string;
    enabled?: boolean;
    linearProgressProps?: LinearProgressProps;
};

export const LinearLoading = (props: LinearLoadingProps) => {
    const { enabled, dataTestId, linearProgressProps, ...otherProps } = props;

    return (
        <LinerLoadingWrapper data-testid={dataTestId} {...otherProps}>
            {enabled && <LinearProgress {...linearProgressProps} />}
        </LinerLoadingWrapper>
    );
};
