import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";

import { useNextTranslation } from "@holibob-packages/ui-core/hooks";

import { LanguageObj, SelectedLanguage } from "../utils/useLanguageSelectionOptions";

type HeaderLanguageSelectProps = {
    buttonClassName?: string;
    onSelect: ({ name, href }: SelectedLanguage) => void;
    selectedLanguage: SelectedLanguage;
    languages: LanguageObj[];
    disabled?: boolean;
};

const SelectStyled = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    width: "100%",
}));

export const HeaderLanguageSelect = ({
    selectedLanguage,
    languages,
    disabled,
    onSelect,
}: HeaderLanguageSelectProps) => {
    const [t] = useNextTranslation("navbar");
    const [selectedValue, setSelectedValue] = useState(selectedLanguage.href);

    const handleLanguageSelection = ({ name, href }: SelectedLanguage) => {
        setSelectedValue(href);
        onSelect({ name, href });
    };

    const selectLanguageLabel = t("label.selectLanguage");
    const selectedLanguageOption = languages.find((l) => l.href === selectedLanguage.href)!;

    return (
        <>
            <FormControl fullWidth variant="outlined">
                <InputLabel>{selectLanguageLabel}</InputLabel>
                <SelectStyled
                    data-testid="language-select-button"
                    value={selectedValue}
                    disabled={disabled}
                    onChange={(event) =>
                        handleLanguageSelection({ name: selectedLanguage.name, href: event.target.value as string })
                    }
                    label={selectLanguageLabel}
                >
                    <MenuItem value={selectedLanguage.href} disabled={true}>
                        {selectedLanguageOption.label}
                    </MenuItem>
                    {languages
                        .filter((l) => l.href !== selectedLanguageOption.href)
                        .map((option) => (
                            <MenuItem key={option.language} value={option.href}>
                                {option.label}
                            </MenuItem>
                        ))}
                </SelectStyled>
            </FormControl>
        </>
    );
};

export default HeaderLanguageSelect;
