import { useViewerCurrencyCodes } from "hooks/useViewer";

export const CURRENCY_MAP: Record<string, { label: string; symbol: string } | undefined> = {
    AED: { label: "AED", symbol: "AED" },
    AUD: { label: "$ AUD", symbol: "$" },
    BRL: { label: "BRL", symbol: "R$" },
    CAD: { label: "CAD", symbol: "$" },
    CHF: { label: "CHF", symbol: "CHF" },
    DKK: { label: "DKK", symbol: "DKK" },
    EGP: { label: "EGP", symbol: "E£" },
    EUR: { label: "€ EUR", symbol: "€" },
    FJD: { label: "FJD", symbol: "FJ$" },
    GBP: { label: "£ GBP", symbol: "£" },
    INR: { label: "INR", symbol: "INR" },
    MXN: { label: "MXN", symbol: "MXN" },
    NAD: { label: "NAD", symbol: "NAD" },
    NOK: { label: "NOK", symbol: "kr" },
    NPR: { label: "NPR", symbol: "रु॰" },
    NZD: { label: "$ NZD", symbol: "$" },
    PEN: { label: "PEN", symbol: "PEN" },
    QAR: { label: "QAR", symbol: "QAR" },
    SAR: { label: "SAR", symbol: "SR" },
    SEK: { label: "SEK", symbol: "SEK" },
    SKD: { label: "$ SGD", symbol: "$" },
    THB: { label: "THB", symbol: "฿" },
    USD: { label: "$ USD", symbol: "$" },
    ZAR: { label: "ZAR", symbol: "ZAR" },
};

export function useCurrencySelectOptions() {
    const viewerCurrencyCodes = useViewerCurrencyCodes();
    const currencySelectOptions = viewerCurrencyCodes.reduce<Array<{ codeWithoutSymbol: string }>>(
        (acc, currency) => {
            const { label } = CURRENCY_MAP[currency] ?? { label: currency };
            const codeWithoutSymbol = label.includes(" ") ? label.split(" ")[1] : label;
            if (label)
                acc.push({
                    codeWithoutSymbol,
                });

            return acc;
        },
        [] as Array<{ codeWithoutSymbol: string }>
    );
    return { currencySelectOptions };
}
