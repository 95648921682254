import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
    List,
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemText,
    Popover,
    PopoverOrigin,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ForwardedRef, forwardRef, useId, useRef, useState } from "react";

import { Link } from "../../navigation/Link";
import { HeaderNavigationNode } from "../HeaderNavigation";

export function HeaderNavigationDesktop({ nodes }: { nodes: HeaderNavigationNode[] }) {
    return <Nodes level={0} nodes={nodes} />;
}

function Nodes({ nodes, level }: { nodes: HeaderNavigationNode[]; level: number }) {
    return (
        <ListWrapper data-level={level}>
            {nodes.map((node, i) => {
                return <Node level={0} node={node} key={i} />;
            })}
        </ListWrapper>
    );
}

const ListWrapper = styled(List)(({ theme }) => ({
    '&[data-level="0"]': {
        display: "none",
        flexDirection: "row",
        gap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "inline-flex",
        },
    },
}));

const ItemWrapper = styled(ListItemButton)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    '&[data-has-submenu="true"][data-level="0"]': {
        paddingRight: theme.spacing(0.5),
    },
}));

const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    minWidth: "min-content",
    marginLeft: theme.spacing(1),
    color: "inherit",
}));

type NodeProps = { level: number; node: HeaderNavigationNode } & ListItemButtonProps;

function Node(props: NodeProps) {
    const hasSubMenu = !!props.node.children?.[0];

    return hasSubMenu ? <NodeWithSubMenu {...props} /> : <NodeCommon {...props} />;
}

const NodeCommon = forwardRef(function NodeCommonInternal(
    { node, level, children, hasSubMenu = false, ...props }: NodeProps & { hasSubMenu?: boolean },
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ItemWrapper
            data-level={level}
            data-has-submenu={hasSubMenu}
            ref={ref}
            {...props}
            {...(node.url && {
                component: Link,
                href: node.url,
                newTab: node.openInNewTab,
            })}
        >
            <ListItemText disableTypography>{node.label}</ListItemText>
            {children}
        </ItemWrapper>
    );
});

const ANCHOR_ORIGIN_MAIN: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "left",
};

const ANCHOR_ORIGIN_SUBMENU: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
};

const PopoverStyled = styled(Popover)({
    pointerEvents: "none",
});

const PopoverContent = styled("div")({
    pointerEvents: "auto",
    overflowY: "auto",
});

function NodeWithSubMenu({ node, level }: NodeProps) {
    const anchorEl = useRef<HTMLDivElement>(null);
    const id = useId();

    const [isOpen, setOpen] = useState(false);

    const nodes = node.children!;
    const newLevel = level + 1;

    const handleToggle = (value: boolean) => {
        setTimeout(() => setOpen(value), 100);
    };

    return (
        <>
            <NodeCommon
                level={level}
                node={node}
                ref={anchorEl}
                selected={isOpen}
                hasSubMenu={true}
                onMouseOver={() => handleToggle(true)}
                onMouseOut={() => handleToggle(false)}
            >
                <ListItemIconStyled>{level === 0 ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</ListItemIconStyled>
            </NodeCommon>
            <PopoverStyled
                id={id}
                PaperProps={{ style: { pointerEvents: "auto" } }}
                elevation={1}
                open={isOpen}
                anchorEl={anchorEl.current}
                hideBackdrop={true}
                anchorOrigin={level === 0 ? ANCHOR_ORIGIN_MAIN : ANCHOR_ORIGIN_SUBMENU}
            >
                <PopoverContent onMouseOver={() => handleToggle(true)} onMouseOut={() => handleToggle(false)}>
                    <ListWrapper data-level={newLevel}>
                        {nodes.map((node, i) => {
                            return <Node level={newLevel} node={node} key={i} />;
                        })}
                    </ListWrapper>
                </PopoverContent>
            </PopoverStyled>
        </>
    );
}
