import { styled } from "@mui/material/styles";
import { HbmlNavigator } from "components/HbmlNavigator";
import Logo, { LogoProps } from "components/Logo";
import { HeaderMenuEntryTreeFragment } from "gql";
import { useBrand } from "hooks/useBrand";
import useLoading from "hooks/useLoading";
import { useReturnLink } from "hooks/usePersistedQueryParams";

import { HeaderBar } from "@holibob-packages/ui-core/components";
import { AssetUrl, assetUrlToVaultUrl } from "@holibob-packages/vault";

import { HeaderButtonProps, HeaderButtons } from "./HeaderButtons/HeaderButtons";

type HeaderLogoProps = Omit<LogoProps, "name" | "src" | "paddingTop" | "paddingBottom" | "href"> & {
    withTransparentHeaderLogo?: boolean;
};
export const HeaderLogo = (props: HeaderLogoProps) => {
    const returnLink = useReturnLink();
    const { withTransparentHeaderLogo, ...restProps } = props;
    const brand = useBrand();

    const headerContrastingLogoAsset = brand.headerContrastingLogo
        ? AssetUrl.fromString(brand.headerContrastingLogo.uri).unwrap()
        : undefined;

    const headerLogoAsset = AssetUrl.fromString(brand.headerLogo.uri).unwrap();

    const src = assetUrlToVaultUrl(
        withTransparentHeaderLogo ? headerContrastingLogoAsset ?? headerLogoAsset : headerLogoAsset
    ).toString();

    return (
        <Logo
            name={brand.name}
            src={src}
            paddingTop={brand.headerLogoPaddingTop}
            paddingBottom={brand.headerLogoPaddingBottom}
            href={returnLink ?? "/"}
            {...restProps}
        />
    );
};

const StyledHeaderBar = styled(HeaderBar)(({ theme }) => ({
    backgroundColor: theme.palette.header.main,
    color: theme.palette.header.contrastText,
}));
export type HeaderProps = {
    navigation?: HeaderMenuEntryTreeFragment[];
    isSticky?: boolean;
    isTransparent?: boolean;
    loading?: boolean;
    extraHeaderBarButtons?: React.ReactNode;
    withGlobalSearchButton?: HeaderButtonProps["withGlobalSearchButton"];
    placeId?: string | null;
} & Record<string, unknown>;
export const Header = ({
    loading: loadingProp,
    navigation,
    withGlobalSearchButton,
    extraHeaderBarButtons,
    ...props
}: HeaderProps) => {
    const loading = useLoading();

    return (
        <StyledHeaderBar
            logo={<HeaderLogo />}
            right={
                <HeaderButtons withGlobalSearchButton={withGlobalSearchButton}>{extraHeaderBarButtons}</HeaderButtons>
            }
            loading={loadingProp ?? loading}
            withScrollTrigger={props.isSticky && props.isTransparent}
            transparentHeaderLogo={<HeaderLogo withTransparentHeaderLogo={true} />}
            navigation={navigation?.length ? <HbmlNavigator navigation={navigation} /> : undefined}
            {...props}
        />
    );
};
