import { HeaderNavigation, HeaderNavigationNode } from "@holibob-packages/ui-core/components";
import { urlCuration, urlBlogPost, urlCustomPage } from "@holibob-packages/url";

import { HeaderMenuEntryTreeFragment } from "../gql";

function buildNavigationNode(node: HeaderMenuEntryTreeFragment): HeaderNavigationNode {
    return {
        label: node.name,
        openInNewTab: node.openInNewTab,
        url: getUrlForNode(node),
        children:
            "children" in node ? node.children.map((x) => buildNavigationNode(x as HeaderMenuEntryTreeFragment)) : [],
    };
}

function getUrlForNode(node: HeaderMenuEntryTreeFragment) {
    if (!node.data) return undefined;

    switch (node.data.__typename) {
        case "HeaderMenuEntryDataCuration": {
            return urlCuration({ curationId: node.data.id });
        }
        case "HeaderMenuEntryDataPath": {
            return node.data.path;
        }
        case "HeaderMenuEntryDataDistributionChannelPage": {
            if (node.data.type === "BLOG") {
                return urlBlogPost(node.data.path);
            } else if (node.data.type === "PAGE") {
                return urlCustomPage(node.data.path);
            }

            throw new Error(`Unsupported DistributionChannelPageType: ${node.data.type}`);
        }
        default: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            throw new Error(`Unsupported type: ${(node.data as any) && (node.data as any).__typename}`);
        }
    }
}

export function HbmlNavigator(props: { navigation: HeaderMenuEntryTreeFragment[] }) {
    const nodesTree = props.navigation.map(buildNavigationNode);
    return <HeaderNavigation nodes={nodesTree} />;
}
