import { AppBar, AppBarProps, ContainerProps, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { ReactNode } from "react";

import { TOOLBAR_HEIGHT } from "../types/HeaderBar";
import { LinearLoading } from "./LinearLoading";

const HEADER_TRANSITION_DURATION = "0.3s";

const Wrapper = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.header.main,
    color: theme.palette.header.contrastText,
    zIndex: theme.zIndex.appBar,
    '&[data-is-transparent="true"]': {
        backgroundColor: "transparent",
        color: theme.palette.common.white,
        transition: theme.transitions.create(["background-color", "color"], {
            duration: HEADER_TRANSITION_DURATION,
            easing: "ease-out",
        }),
        '& [data-logo-contrasting="true"]': {
            opacity: 1,
            transition: theme.transitions.create(["opacity"], {
                duration: HEADER_TRANSITION_DURATION,
                easing: "ease-out",
            }),
        },
        '& [data-logo="true"]': {
            opacity: 0,
            position: "absolute",
            top: 0,
            transition: theme.transitions.create(["opacity"], {
                duration: HEADER_TRANSITION_DURATION,
                easing: "ease-out",
            }),
        },
        '&[data-scroll-trigger="true"]': {
            backgroundColor: theme.palette.header.main,
            color: theme.palette.header.contrastText,
            transition: theme.transitions.create(["background-color", "color"], {
                duration: HEADER_TRANSITION_DURATION,
                easing: "ease-in",
            }),
            '& [data-logo-contrasting="true"]': {
                opacity: 0,
                transition: theme.transitions.create(["opacity"], {
                    duration: HEADER_TRANSITION_DURATION,
                    easing: "ease-in",
                }),
            },
            '& [data-logo="true"]': {
                opacity: 1,
                transition: theme.transitions.create(["opacity"], {
                    duration: HEADER_TRANSITION_DURATION,
                    easing: "ease-in",
                }),
            },
        },
    },
}));

export type HeaderBarDefaultProps = {
    logo: React.ReactNode;
    transparentHeaderLogo?: React.ReactNode;
    navigation?: React.ReactNode;
    right?: React.ReactNode;
    maxWidth?: ContainerProps["maxWidth"];
    isTransparent?: boolean;
    isSticky?: boolean;
    loading?: boolean;
    withScrollTrigger?: boolean;
    scrollTriggered?: boolean;
} & AppBarProps;

const LogoWrapper = styled("div")({
    order: 5,
    flexShrink: 100,
    position: "relative",
});
const ToolbarWrapper = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    '&[data-has-navigation="false"]': {
        [theme.breakpoints.up("md")]: {
            justifyContent: "space-between",
        },
    },
    '&[data-is-sticky="true"]': {
        height: TOOLBAR_HEIGHT.sm,
        [theme.breakpoints.up("sm")]: {
            height: TOOLBAR_HEIGHT.md,
        },
        [theme.breakpoints.up("md")]: {
            height: TOOLBAR_HEIGHT.lg,
        },
    },
}));

const NavigationWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    order: 0,
    [theme.breakpoints.up("md")]: {
        paddingLeft: 20,
        flexGrow: 100,
        justifyContent: "flex-start",
        order: 10,
    },
}));

const RightWrapper = styled("div")(({ theme }) => ({
    flexShrink: 0,
    order: 15,
    flexGrow: 100,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
        flexGrow: 0,
    },
}));

const Loading = styled(LinearLoading)({
    position: "absolute",
    '&[data-alignment="top"]': { top: 0 },
    '&[data-alignment="bottom"]': { bottom: 0 },
});

type LogoContainerProps = {
    logo: ReactNode;
    transparentHeaderLogo?: ReactNode;
    isTransparent?: boolean;
    withScrollTrigger?: boolean;
};

const LogoContainer = (props: LogoContainerProps) => {
    const { isTransparent, logo, transparentHeaderLogo, withScrollTrigger } = props;
    const withLogoChangeOnScroll = withScrollTrigger && isTransparent;

    if (withLogoChangeOnScroll) {
        return (
            <LogoWrapper>
                <div data-logo-contrasting="true">{transparentHeaderLogo ?? logo}</div>
                <div data-logo="true">{logo}</div>
            </LogoWrapper>
        );
    }

    return <LogoWrapper>{isTransparent && transparentHeaderLogo ? transparentHeaderLogo : logo}</LogoWrapper>;
};

const HeaderBarDefault = (props: HeaderBarDefaultProps) => {
    const {
        logo,
        transparentHeaderLogo,
        navigation,
        right,
        isTransparent = false,
        isSticky = false,
        loading,
        scrollTriggered,
        withScrollTrigger,
        ...restProps
    } = props;
    const position = isSticky ? (isTransparent ? "fixed" : "sticky") : isTransparent ? "absolute" : "relative";
    const showLoader = !isTransparent || loading;
    const loaderAlignment = isTransparent ? "top" : "bottom";

    return (
        <Wrapper
            position={position}
            elevation={0}
            data-is-transparent={isTransparent}
            data-scroll-trigger={scrollTriggered}
            {...restProps}
        >
            <nav>
                <ToolbarWrapper data-has-navigation={!!navigation} data-is-sticky={isSticky}>
                    <LogoContainer
                        logo={logo}
                        transparentHeaderLogo={transparentHeaderLogo}
                        isTransparent={isTransparent}
                        withScrollTrigger={withScrollTrigger}
                    />
                    {navigation && <NavigationWrapper>{navigation}</NavigationWrapper>}
                    {right && <RightWrapper>{right}</RightWrapper>}
                </ToolbarWrapper>
            </nav>
            {showLoader && <Loading data-test-id="loadingBar" data-alignment={loaderAlignment} enabled={loading} />}
        </Wrapper>
    );
};

type HeaderBarWithScrollTriggerProps = HeaderBarDefaultProps & {
    scrollTriggerProps?: { disableHysteresis?: boolean; threshold?: number; target?: Node | Window };
};

const HeaderBarWithScrollTrigger = (props: HeaderBarWithScrollTriggerProps) => {
    const { scrollTriggerProps, ...restProps } = props;
    const { disableHysteresis = true, threshold = 50, target } = scrollTriggerProps ?? {};

    const trigger = useScrollTrigger({ disableHysteresis, threshold, target });

    return <HeaderBarDefault {...restProps} withScrollTrigger={true} scrollTriggered={trigger} />;
};

export type HeaderBarProps = HeaderBarDefaultProps & {
    withScrollTrigger?: boolean;
    scrollTriggerProps?: HeaderBarWithScrollTriggerProps["scrollTriggerProps"];
};

export const HeaderBar = (props: HeaderBarProps) => {
    const { withScrollTrigger, scrollTriggerProps, ...headerDefaultProps } = props;

    if (withScrollTrigger) {
        return <HeaderBarWithScrollTrigger scrollTriggerProps={scrollTriggerProps} {...headerDefaultProps} />;
    }

    return <HeaderBarDefault {...headerDefaultProps} />;
};
