import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CartIcon from "@mui/icons-material/ShoppingBasket";
import { IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import { ButtonPrimary, ButtonSecondary } from "components/Button";
import LabelValue from "components/LabelValue";
import Text from "components/Text";
import utilStyles from "components/utilStyles.module.css";
import { useIsBookingPage } from "hooks/useIsBookingPage";
import {
    useShoppingCartBooking,
    useShoppingCartDeleteAvailability,
    useShoppingCartBookingAvailabilities,
} from "hooks/useShoppingCart";
import { useNextTranslation } from "hooks/useTranslation";
import { useViewerHasShoppingCart } from "hooks/useViewer";
import { useState, useCallback } from "react";

import { Spacer } from "@holibob-packages/ui-core/components";
import { makeStyles, withStyles, useStyles } from "@holibob-packages/ui-core/style";

const useSelectStyles = makeStyles()({
    summaryImage: {
        height: "100%",
        width: "100%",
    },

    deleteButtonContainer: {},
    deleteConfirm: {
        backgroundColor: "red",
        marginLeft: 30,
        marginBottom: 10,
    },
    menu: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    title: {
        backgroundColor: "#f5f5f5",
    },
    fontSize: {
        fontSize: "14px",
    },
    cartButton: {},
    closeIcon: {
        backgroundColor: "#f5f5f5",
        position: "absolute",
        right: "30px",
        top: "20px",
    },
});

const StyledBadge = withStyles(Badge, {
    badge: {
        right: -6,
        top: 13,
        padding: "0 4px",
    },
});

const RemoveButton = ({ availabilityId }: $TSFixMe) => {
    const { classes } = useSelectStyles();
    const bookingDeleteAvailability = useShoppingCartDeleteAvailability();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    const onAvailabilityDelete = useCallback(async () => {
        setLoading(true);
        await bookingDeleteAvailability(availabilityId);
        setLoading(false);
    }, [availabilityId, bookingDeleteAvailability, setLoading]);

    const onClick = useCallback(() => {
        setShowConfirmation(!showConfirmation);
        setTimeout(() => {
            try {
                setShowConfirmation(false);
            } catch (error) {
                // in case the timer fires after component unmounts;
            }
        }, 3000);
    }, [showConfirmation, setShowConfirmation]);
    const availabilities = useShoppingCartBookingAvailabilities();

    if (availabilities.length > 1) {
        if (showConfirmation) {
            return (
                <ButtonSecondary
                    className={classes.deleteConfirm}
                    size="small"
                    onClick={onAvailabilityDelete}
                    disabled={loading}
                >
                    Confirm Delete
                    {loading && <CircularProgress size={20} />}
                </ButtonSecondary>
            );
        }
        return (
            <ButtonPrimary onClick={onClick}>
                <DeleteIcon color="primary" />
            </ButtonPrimary>
        );
    }
    return null;
};

const BookingSummaryAvailability = ({ availability }: $TSFixMe) => {
    const { id, product = {}, totalPrice } = availability;

    const { classes } = useSelectStyles();

    const { name, previewImage = {} } = product;
    //FIXME defaultImage is not defined
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'defaultImage'.

    const { url } = previewImage || { url: defaultImage };

    const { grossFormattedText } = totalPrice;
    //FIXME use Next/Image
    /* eslint-disable */
    let media = (
        <div>
            <img src={url} alt="product preview" className={classes.summaryImage} />
        </div>
    );

    return (
        <>
            <Grid container justifyContent="center" alignItems="flex-start" spacing={1}>
                <Grid item xs={5} sm={5} md={5} xl={5}>
                    {media}
                </Grid>
                <Grid item xs={7} sm={7} md={7} xl={7}>
                    <Text variant="body2">{grossFormattedText} </Text>
                    <Text variant="caption">{name}</Text>
                    <div className={classes.deleteButtonContainer}>
                        <RemoveButton availabilityId={id} />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

const BookingAvaliabilityMenu = ({ anchorEl, className, handleClose, availabilities, booking }: $TSFixMe) => {
    const { classes, cx } = useSelectStyles();
    const [t] = useNextTranslation("bookingNavigation");
    const basketLabel = t("basket.label");
    const subTotalLabel = t("subTotal.label");
    const goToCheckoutLabel = t("goToCheckout.label");
    const itemsLabel = t("items.label");
    const itemLabel = t("item.label");

    let itemText = `${availabilities.length} ${itemLabel}`;
    if (availabilities.length > 1) itemText = `${availabilities.length} ${itemsLabel}`;
    const { totalPrice, id: bookingId } = booking;
    const { grossFormattedText } = totalPrice || {};

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            PaperProps={{
                style: {
                    width: 350,
                },
            }}
            MenuListProps={{ style: { padding: 0 } }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={cx(classes.menu, className)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <CardHeader
                className={classes.title}
                titleTypographyProps={{ variant: "body1" }}
                action={
                    <CloseIcon aria-label="close" color="primary" onClick={handleClose} className={classes.closeIcon} />
                }
                title={`${basketLabel} -  ${itemText}`}
            />
            <Divider />

            <CardContent>
                {availabilities.map((availability: $TSFixMe, idx: $TSFixMe) => (
                    <BookingSummaryAvailability key={idx} availability={availability} />
                ))}

                <Spacer xs />
            </CardContent>
            <Divider />
            <CardContent className={classes.title}>
                <LabelValue label={subTotalLabel} className={classes.fontSize} value={grossFormattedText} />
                <ButtonPrimary href={`/booking/${bookingId}`}>{goToCheckoutLabel}</ButtonPrimary>
            </CardContent>
        </Menu>
    );
};
const HeaderCartButton = ({ buttonClassName }: { buttonClassName?: string }) => {
    const { cx } = useStyles();
    const hasShoppingCart = useViewerHasShoppingCart();
    const availabilities = useShoppingCartBookingAvailabilities();
    const booking = useShoppingCartBooking();
    const isBookingPage = useIsBookingPage();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!hasShoppingCart || !booking || isBookingPage) return null;

    const cartIcon = (
        <StyledBadge badgeContent={availabilities.length}>
            <CartIcon />
        </StyledBadge>
    );

    return (
        <>
            <IconButton
                className={utilStyles.desktopHidden}
                edge="end"
                color="inherit"
                aria-label="menu"
                href={`/booking/${booking.id}`}
                size="small"
            >
                {cartIcon}
            </IconButton>

            <ButtonPrimary
                aria-label="menu"
                className={cx(buttonClassName, utilStyles.mobileHidden)}
                onClick={handleClick}
            >
                {cartIcon}
            </ButtonPrimary>
            <BookingAvaliabilityMenu
                className={utilStyles.mobileHidden}
                anchorEl={anchorEl}
                availabilities={availabilities}
                booking={booking}
                handleClose={handleClose}
            />
        </>
    );
};

export default HeaderCartButton;
