import { HeaderNavigationDesktop } from "./HeaderNavigation/HeaderNavigationDesktop";
import { HeaderNavigationMobile } from "./HeaderNavigation/HeaderNavigationMobile";

export type HeaderNavigationNode = {
    label: string;
    url?: string;
    children?: HeaderNavigationNode[];
    openInNewTab?: boolean;
};

export type HeaderNavigationProps = {
    nodes: HeaderNavigationNode[];
};

export function HeaderNavigation({ nodes }: HeaderNavigationProps) {
    if (nodes.length === 0) {
        return null;
    }

    return (
        <>
            <HeaderNavigationDesktop nodes={nodes} />
            <HeaderNavigationMobile nodes={nodes} />
        </>
    );
}
