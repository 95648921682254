import { vaultUrlLoader } from "utils/vaultUrlLoader";

import { Flex } from "@holibob-packages/ui-core/components";
import { Link } from "@holibob-packages/ui-core/navigation";
import { makeStyles } from "@holibob-packages/ui-core/style";

interface MakeStylesProps {
    paddingTop: number | null;
    paddingBottom: number | null;
}
const useStyles = makeStyles<MakeStylesProps>()((theme, { paddingTop, paddingBottom }) => ({
    wrapper: {
        height: 80,
        boxSizing: "border-box",
        paddingTop: paddingTop ?? 13,
        paddingBottom: paddingBottom ?? 17,
        [theme.breakpoints.down("md")]: {
            height: 56,
            paddingTop: paddingTop ?? 10,
            paddingBottom: paddingBottom ?? 10,
        },
    },
    logoLink: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    logoImage: {
        maxHeight: "100%",
        "@media (max-width:359px)": {
            maxWidth: 100,
        },
        maxWidth: "100%",
    },
}));

export type LogoProps = {
    name: string;
    src: string;
    href?: string;
    paddingTop?: number | null;
    paddingBottom?: number | null;
};
export const Logo = ({ name, href, src, paddingTop = 0, paddingBottom = 0 }: LogoProps) => {
    const { classes } = useStyles({ paddingTop, paddingBottom });

    if (!src) return null;

    /* eslint-disable @next/next/no-img-element */
    return (
        <Flex start middle className={classes.wrapper}>
            <Link href={href ?? "/"} className={classes.logoLink}>
                {/**
                 * The sizes below are very sub-optimal, but we're trying to account for
                 * both square icons and large logos that cover more than half the window width on mobile.
                 */}
                <img
                    data-testid="logoImage"
                    alt={name ? `${name} Logo` : "Logo"}
                    srcSet={[48, 64, 96, 128, 256, 384, 640]
                        .map((width) => `${vaultUrlLoader({ src, width })} ${width}w`)
                        .join(", ")}
                    sizes="
                    (max-width: 640px) 80vw,
                    (max-width: 960px) 50vw,
                    (max-width: 1280px) 33vw,
                    25vw
                    "
                    src={vaultUrlLoader({ src, width: 384 })}
                    className={classes.logoImage}
                />
            </Link>
        </Flex>
    );
    /* eslint-enable @next/next/no-img-element */
};

export default Logo;
