import { styled } from "@mui/material/styles";
import { useAuthToken } from "contexts/AuthTokenContext";
import dynamic from "next/dynamic";

import { makeStyles } from "@holibob-packages/ui-core/style";

import { HeaderLanguageAndCurrencyButton } from "../HeaderLanguageAndCurrencyModal";
import HeaderCartButton from "./HeaderCartButton";
import { HeaderGlobalSearchButton } from "./HeaderGlobalSearchButton";

const HeaderAuthMenu = dynamic(() => import("./HeaderAuthMenu").then((mod) => mod.HeaderAuthMenu));

const useStyles = makeStyles()((theme) => ({
    container: {
        position: "relative",
        flexGrow: 1,
        zIndex: 200,
    },
    header: {
        backgroundColor: theme.palette.header.main,
        color: theme.palette.header.contrastText,
    },
    button: {
        height: 41,
        minWidth: 35,
        whiteSpace: "nowrap",
    },
}));

export type HeaderButtonProps = {
    children?: React.ReactNode;
    withGlobalSearchButton?: boolean;
};

export const HeaderButtons = ({ children, withGlobalSearchButton = true }: HeaderButtonProps) => {
    const { classes } = useStyles();
    const isAuthenticated = !!useAuthToken();
    const showAuthMenu = isAuthenticated;

    return (
        <>
            <HeaderButtonsWrapper>
                {children}
                <HeaderLanguageAndCurrencyButton />
                {withGlobalSearchButton && <HeaderGlobalSearchButton buttonClassName={classes.button} />}
                <HeaderCartButton buttonClassName={classes.button} />
                {showAuthMenu && <HeaderAuthMenu buttonClassName={classes.button} />}
            </HeaderButtonsWrapper>
        </>
    );
};

const HeaderButtonsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
}));
