import { useCallback } from "react";

import useLocalStorage, { useDeleteFromLocalStorage } from "./useLocalStorage";

type Booking = {
    id?: string;
};

export const useStoreBooking = () => {
    const key = "bookingId";

    const [storedValue, setStoredValue] = useLocalStorage<string | undefined>(key, undefined);

    const setStoreBooking = useCallback(
        ({ id }: Booking) => {
            if (id) {
                setStoredValue(key, id);
            }
        },
        [setStoredValue]
    );

    return [setStoreBooking, storedValue] as const;
};

export const useDeleteStoreBooking = () => {
    const key = "bookingId";

    const [deleteValue] = useDeleteFromLocalStorage();

    const deleteStoreBooking = useCallback(() => {
        deleteValue(key);
    }, [deleteValue]);

    return [deleteStoreBooking] as const;
};

export default useStoreBooking;
