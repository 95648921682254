import { ImageLoader } from "next/legacy/image";

import { base64decode, base64encode } from "@holibob-packages/ui-core/utils";

const VAULT_IMAGE_PREFIX = "https://images.holibob.tech/";

/**
 * Given a vault image url like https://images.holibob.tech/asdfasdfasdfasdfasdfasdf
 * Extract the base64 data, apply edits and return the resulting vault image url
 */
export const editVaultImage = (imageUrl: string, edits?: Record<string, unknown>) => {
    if (!imageUrl.startsWith(VAULT_IMAGE_PREFIX) || !edits) {
        return imageUrl;
    }
    try {
        const base64 = imageUrl.substring(VAULT_IMAGE_PREFIX.length);
        const json = JSON.parse(base64decode(base64));
        return VAULT_IMAGE_PREFIX + base64encode(JSON.stringify({ ...json, edits }));
    } catch (e) {
        return imageUrl;
    }
};

/**
 * Allow next/image to load raw vault image urls and apply resize to them
 */
export const vaultUrlLoader: ImageLoader = ({ src, width }: { src: string; width: number }) => {
    return editVaultImage(src, { resize: { fit: "cover", width } });
};
